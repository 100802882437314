<template>
    <div class="bg-black text-white min-h-screen">
        <!-- Desktop Layout -->
        <div class="hidden lg:block">
            <header class="flex justify-between items-center p-6">
                <div class="text-2xl font-bold">
                    <img src="@/assets/logo.png" width="150px" alt="TCGDeckHub" />
                </div>
                <nav class="space-x-6">
                    <router-link class="hover:text-purple-500" to="/">Home</router-link>
                    <router-link class="hover:text-purple-500" to="/privacy">Privacy</router-link>
                    <router-link class="hover:text-purple-500" to="/terms">Terms</router-link>
                </nav>
                <div class="space-x-4">
                    <a href="https://x.com/TCGDeckHub/status/1850940189423939940" target="_blank" class="px-4 py-2 rounded-lg bg-gray-800 text-white border border-gray-700 hover:bg-gray-700 transition-colors text-center"> 立即體驗內測版 </a>
                </div>
            </header>
            <router-view />
        </div>

        <!-- Mobile Layout -->
        <div class="lg:hidden">
            <header class="flex justify-between items-center p-4">
                <div class="text-xl font-bold">
                    <img src="@/assets/logo.png" width="150px" alt="TCGDeckHub" />
                </div>
                <button @click="isMenuOpen = !isMenuOpen">
                    <Menu class="w-6 h-6" />
                </button>
            </header>

            <!-- Mobile Menu Overlay -->
            <div v-if="isMenuOpen" class="fixed inset-0 bg-black bg-opacity-50 z-40" @click="isMenuOpen = false"></div>

            <!-- Mobile Menu -->
            <div v-if="isMenuOpen" class="fixed top-0 right-0 w-64 h-auto bg-gray-900 z-50 rounded-bl-lg shadow-lg transform transition-transform duration-200 ease-in-out">
                <div class="p-4">
                    <div class="flex justify-end mb-4">
                        <button @click="isMenuOpen = false">
                            <X class="w-6 h-6" />
                        </button>
                    </div>
                    <nav class="flex flex-col space-y-4">
                        <router-link v-for="route in routes" :key="route.path" :to="route.path" class="text-white hover:text-purple-500 py-2" @click="isMenuOpen = false">
                            {{ route.name }}
                        </router-link>
                        <a href="https://x.com/TCGDeckHub/status/1850940189423939940" target="_blank" class="px-4 py-2 rounded-lg bg-gray-800 text-white border border-gray-700 hover:bg-gray-700 transition-colors text-center" @click="isMenuOpen = false"> 立即體驗內測版 </a>
                    </nav>
                </div>
            </div>
            <router-view />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { Menu, X } from 'lucide-vue-next'

const isMenuOpen = ref(false)

const routes = [
    { path: '/', name: 'Home' },
    { path: '/privacy', name: 'Privacy' },
    { path: '/terms', name: 'Terms' }
]
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

body {
    background-color: black;
}

/* 移除不需要的样式 */
</style>
