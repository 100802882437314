<template>
    <div class="bg-black text-white min-h-screen">
        <!-- Desktop Layout -->
        <div class="hidden lg:block">
            <main class="flex p-12">
                <div class="w-1/2 space-y-6 flex flex-col items-center">
                    <div class="inline-flex items-center rounded-full px-2 py-1 text-sm" style="background-color: #f9f5ff; color: #6941c6; font-weight: 500">
                        <span class="bg-purple-500 text-white rounded-full px-2 py-1 mr-2">New!</span>
                        <!-- Refer a friend & Get 25% → -->
                        TCGDeckHub現已支援寶可夢卡牌遊戲！
                    </div>
                    <h1 class="text-5xl font-bold">我們提供工具 <span class="text-purple-500">All-in-one.</span> 管理你所有的牌組</h1>
                    <p class="text-gray-400">TCGDeckHub是一個支援多種卡牌遊戲的構築的TCG社群APP</p>
                    <!-- <form @submit.prevent="joinWhitelist" class="space-y-4 w-2/3">
                        <div class="flex flex-col space-y-2">
                            <label for="email-desktop" class="sr-only">Enter your email to join the whitelist</label>
                            <input id="email-desktop" v-model="email" type="email" placeholder="輸入您的信箱試用搶先體驗版" required class="px-4 py-2 rounded-lg bg-gray-800 text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500" />
                        </div>
                        <button type="submit" class="w-full bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-purple-600 transition-colors ">Join Whitelist</button>
                    </form> -->
                    <form @submit.prevent="joinWhitelist" class="space-y-4 w-1/3">
                        <a href="https://x.com/TCGDeckHub/status/1850940189423939940" target="_blank" class="block px-4 py-2 rounded-lg bg-gray-800 text-white border border-gray-700 hover:bg-gray-700 transition-colors text-center"> 立即體驗內測版 </a>
                    </form>
                    <div class="flex flex-col items-center">
                        <p class="text-gray-400 mb-4">我們即將上線以下平台</p>
                        <div class="flex space-x-4">
                            <img src="@/assets/images/appstore.png" width="150" height="50" alt="App Store" class="bg-white rounded-lg" />
                            <img src="@/assets/images/googleplay.png" width="150" height="50" alt="Google Play" class="bg-white rounded-lg" />
                        </div>
                    </div>
                </div>
                <div class="w-1/2 relative ml-20">
                    <img src="@/assets/images/product-icon-1.jpg" alt="Card" class="absolute top-0 left-0 bg-gray-800 rounded-lg" style="max-height: 70vh" />
                </div>
            </main>
            <footer class="p-12 text-center">
                <!-- <p class="mb-4">Trusted by 3000+ partners & customers</p>
                <div class="flex justify-center space-x-8">
                    <div v-for="partner in partners" :key="partner" class="text-gray-500">{{ partner }}</div>
                </div> -->
            </footer>
        </div>

        <!-- Mobile Layout -->
        <div class="lg:hidden">
            <main class="p-6 space-y-6 flex flex-col items-center">
                <div class="inline-flex items-center rounded-full px-2 py-1 text-sm" style="background-color: #f9f5ff; color: #6941c6; font-weight: 500">
                    <span class="bg-purple-500 text-white rounded-full px-2 py-1 mr-2">New!</span>
                    現已支援寶可夢卡牌遊戲！
                </div>
                <h1 class="text-3xl font-bold">我們提供工具 <span class="text-purple-500">All-in-one.</span> 管理你所有的牌組</h1>
                <p class="text-gray-400 text-sm">TCGDeckHub是一個支援多種卡牌遊戲的構築的TCG社群APP</p>
                <!-- <form @submit.prevent="joinWhitelist" class="space-y-4 w-2/3">
                    <div class="flex flex-col space-y-2">
                        <label for="email-mobile" class="sr-only">Enter your email to join the whitelist</label>
                        <input id="email-mobile" v-model="email" type="email" placeholder="輸入您的信箱試用搶先體驗版" required class="px-4 py-2 rounded-lg bg-gray-800 text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500" />
                    </div>
                    <button type="submit" class="w-full bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-purple-600 transition-colors">Join Whitelist</button>
                </form> -->
                <form @submit.prevent="joinWhitelist" class="space-y-4 w-1/3">
                    <a href="https://x.com/TCGDeckHub/status/1850940189423939940" target="_blank" class="block px-4 py-2 rounded-lg bg-gray-800 text-white border border-gray-700 hover:bg-gray-700 transition-colors text-center"> 立即體驗內測版 </a>
                </form>

                <div class="flex flex-col items-center">
                    <p class="text-gray-400 mb-4 text-sm">我們即將上線以下平台</p>
                    <div class="flex space-x-4">
                        <img src="@/assets/images/appstore.png" width="120" height="40" alt="App Store" class="bg-white rounded-lg" />
                        <img src="@/assets/images/googleplay.png" width="120" height="40" alt="Google Play" class="bg-white rounded-lg" />
                    </div>
                </div>
                <div class="relative h-80" style="width: 300px">
                    <img src="@/assets/images/product-icon-1.jpg" alt="Card" class="absolute top-10 left-0 bg-gray-800 rounded-lg" style="width: 300px" />
                </div>
            </main>
            <footer class="p-6 text-center">
                <!-- <p class="mb-4 text-sm">Trusted by 3000+ partners & customers</p>
                <div class="flex flex-wrap justify-center gap-4">
                    <div v-for="partner in partners" :key="partner" class="text-gray-500 text-xs">{{ partner }}</div>
                </div> -->
            </footer>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: 'HomeView',
    components: {
        HelloWorld
    }
}
</script>
